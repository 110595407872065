import { Analytics } from "aws-amplify";

export const setValueOnOption = (qkey, vkey, value, questions) => {
  let setValueAllready = false;

  for (var prop in questions) {
    let answerIsForThisQuestion = false;
    if (questions.key === qkey) {
      answerIsForThisQuestion = true; // the first is the right!!!!
    }

    if (prop === "options") {
      for (var prop2 in questions[prop]) {
        if (questions[prop][prop2].value === vkey && answerIsForThisQuestion) {
          questions[prop][prop2].valueStored = value;
          let analyticsEvent = {
            name: "Robo Antwort",
            attributes: { param: questions.param, answer: prop2 }
          };
          Analytics.record(analyticsEvent);
          console.log("AnalyticsEvent ", analyticsEvent);
          setValueAllready = true;
        } else {
          if (answerIsForThisQuestion) {
            delete questions[prop][prop2].valueStored;
          }
        }
        if (questions[prop][prop2].go_on && !setValueAllready) {
          for (var i = 0; i < questions[prop][prop2].go_on.length; i++) {
            questions[prop][prop2].go_on[i] = setValueOnOption(
              qkey,
              vkey,
              value,
              questions[prop][prop2].go_on[i]
            );
          }
        }
      }
    }
  }
  return questions;
};

export const setValueOnQuestion = (qkey, value, questions) => {
  let setValueAllready = false;

  for (var prop in questions) {
    //let answerIsForThisQuestion = false;
    if (questions.key === qkey) {
      questions.valueStored = value;
      setValueAllready = true;
      let analyticsEvent = {
        name: "Robo Antwort",
        attributes: { param: questions.param, answer: value.toString() }
      };
      Analytics.record(analyticsEvent);
      console.log("AnalyticsEvent ", analyticsEvent);
    }

    if (prop === "options") {
      for (var prop2 in questions[prop]) {
        if (questions[prop][prop2].go_on && !setValueAllready) {
          for (var i = 0; i < questions[prop][prop2].go_on.length; i++) {
            questions[prop][prop2].go_on[i] = setValueOnQuestion(
              qkey,
              value,
              questions[prop][prop2].go_on[i]
            );
          }
        }
      }
    }
  }
  return questions;
};

export const setClientTyp = clientType => {
  return {
    type: "SET_CLIENTTYPE",
    clientType: clientType
  };
};

export const setNewProduct = state => {
  return {
    type: "SET_NEWPRODUCT",
    newProductState: state
  };
};
export const loadHistoricData = (type, response) => {
  return (dispatch, getState) => {
    dispatch({ type: "LOADHISTORICDATA_" + type, response });
  };
};

export const setRoboQuestions = (newStrategy, conf) => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_ROBO_QUESTIONS", newStrategy, conf });
  };
};
export const setRoboUserData = person => {
  return (dispatch, getState) => {
    dispatch({ type: "SET_ROBO_USERDATA", person });
  };
};

export const updateUserData = values => ({
  type: "UPDATE_USERDATA",
  values: values
});
//updateAnswers & updateInput both update roboData.userData based on the type question (selection or other)
export const updateAnswers = (option, question) => ({
  type: "UPDATE_ANSWER",
  option: option,
  question: question
});
export const updateInput = (question, values) => ({
  type: "UPDATE_INPUT",
  question: question,
  values: values
});

export const updateQuestions = question => ({
  type: "UPDATE_QUESTIONS",
  question: question
});
export const nextQuestion = () => ({ type: "NEXT_QUESTION" });
export const previousQuestion = (stateAffected, question) => ({
  type: "PREVIOUS_QUESTION",
  stateAffected: stateAffected,
  question: question
});
export const calculateAnswer = () => ({ type: "CALCULATE_ANSWER" });
export const calculateStrategy = () => ({ type: "CALCULATE_STRATEGY" });
export const updateConditions = () => ({ type: "UPDATE_CONDITIONS" });
export const getStdDev = () => ({ type: "GET_STD_DEVIATION" });

export const reset = () => ({ type: "RESET" });
export const resetRobo = () => ({ type: "RESET" });
