export default [
  { "01": "Arbeiter/Angestellter" },
  { "02": "Arbeitsloser" },
  { "03": "Beamter" },
  { "04": "Rentner/Pensionäre" },
  { "05": "Privatier" },
  { "06": "Hausfrau/-mann" },
  { "07": "Schüler/Student" },
  { "08": "Auszubildender" },
  { "09": "Zivildienstleistender" },
  { "10": "Selbständig" },
  { "11": "sonstige Privatperson" }
];
